import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  // State to hold match data
  const [allPastMatches, setAllPastMatches] = useState([]);
  const [allTodayMatches, setAllTodayMatches] = useState([]);
  const [allFutureMatches, setAllFutureMatches] = useState([]);
  
  // State to control the number of matches shown
  const [shownPastMatches, setShownPastMatches] = useState(5);
  const [shownTodayMatches, setShownTodayMatches] = useState(10);
  const [shownFutureMatches, setShownFutureMatches] = useState(5);

  // Fetch data from the server when the component mounts
  useEffect(() => {
    async function fetchMatches() {
        try {
            const response = await fetch('https://www.lolpredict.com/predictions'); // Update to your server's endpoint
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();

            // Sort past_predictions by date in descending order (most recent first)
            const sortedPastMatches = data.past_predictions.sort((a, b) => new Date(b.date) - new Date(a.date));

            // Set the sorted and unsorted match data into state
            setAllPastMatches(sortedPastMatches);
            setAllTodayMatches(data.today_predictions);
            setAllFutureMatches(data.upcoming_predictions);
        } catch (error) {
            console.error('Error fetching match data:', error);

            // Set the states to empty arrays in case of an error
            setAllPastMatches([]);
            setAllTodayMatches([]);
            setAllFutureMatches([]);
        }
    }

    fetchMatches();
}, []);

  // Functions to load more matches
  const loadMorePastMatches = () => setShownPastMatches(prev => prev + 5);
  const loadMoreTodayMatches = () => setShownTodayMatches(prev => prev + 10);
  const loadMoreFutureMatches = () => setShownFutureMatches(prev => prev + 5);

  return (
    <div className="App">
      {/* Navbar */}
      <nav className="navbar">
        <a href="/" className="navbar-brand">
          LOL Prediction
        </a>
        <div className="navbar-links">
          <a href="#faq" className="navbar-link">FAQ</a>
          <a href="#contact" className="navbar-link">Contact Me</a>
        </div>
      </nav>

      {/* Main Content Area */}
      <div className="main-content">
        {/* Past Matches */}
        <section className="match-section">
          <h2>Past Matches</h2>
          {allPastMatches.slice(0, shownPastMatches).map((match, index) => (
            <MatchRow key={index} match={match} />
          ))}
          {shownPastMatches < allPastMatches.length && (
            <button className="load-more" onClick={loadMorePastMatches}>Load more</button>
          )}
        </section>

        {/* Today's Matches */}
        <section className="match-section">
          <h2>Today's Matches</h2>
          {allTodayMatches.slice(0, shownTodayMatches).map((match, index) => (
            <MatchRow key={index} match={match} />
          ))}
          {shownTodayMatches < allTodayMatches.length && (
            <button className="load-more" onClick={loadMoreTodayMatches}>Load more</button>
          )}
        </section>

        {/* Future Matches */}
        <section className="match-section">
          <h2>Future Matches</h2>
          {allFutureMatches.slice(0, shownFutureMatches).map((match, index) => (
            <MatchRow key={index} match={match} />
          ))}
          {shownFutureMatches < allFutureMatches.length && (
            <button className="load-more" onClick={loadMoreFutureMatches}>Load more</button>
          )}
        </section>
      </div>

      {/* FAQ Section */}
      <section id="faq" className="faq-section">
      <h2>FAQ</h2>

      <h3>What do the colors green, red, and blue stand for?</h3>
      <p>Blue indicates teams that are predicted to win, green indicates correct predictions, and red indicates incorrect predictions.</p>

      <h3>What is the performance of the model?</h3>
      <p>The prematch prediction model has achieved more than 70% accuracy across over 20 leagues and 2600 matches throughout 2024.</p>

      <h3>Can I use it for betting?</h3>
      <p>This project is intended solely for study purposes and does not guarantee a profitable outcome.</p>

      <h3>Why do I sometimes see different predictions?</h3>
      <p>The model is updated regularly, which means you may see different prediction results at various times.</p>

      <h3>Why some matches are missing?</h3>
      <p>Some matches are not included in the dataset due to various reasons, most are caused by data unavailability.</p>
      <h3>I am interested to cooperate. How can I find you?</h3>
      <p>See below.</p>
    </section>

      {/* Contact Me Section */}
      <section id="contact" className="contact-section">
        <h2>Contact Me</h2>
        <p>Discord: kor_lol_predict
        </p>
        <p>LinkedIn: <a href="https://www.linkedin.com/in/wenqiao-wang-98b975250" target="_blank">www.linkedin.com/in/wenqiao-wang-98b975250</a></p>
        <p>Email:wenqiaow@usc.edu</p>
        {/* You can include a form here for users to send messages */}
      </section>
    </div>
  );
}
function MatchRow({ match }) {
  const isFinished = match.status === 'finished';
  const isRunning = match.status === 'running';
    // Determine classes for team 1 (home team)
    let team1Class = '';
    if (isFinished) {
      if (match.Home_win) {
        // Predicted home team to win
        if (match.Match) {
          // Prediction was correct, home team actually won
          team1Class = 'winner'; // Green for correct prediction
        } else {
          // Prediction was incorrect, home team lost
          team1Class = 'wrong'; // Yellow for incorrect prediction
        }
      } else {
        team1Class = ''; // Default styling if prediction was for away to win
      }
    } else {
      // Match not finished yet
      team1Class = match.Home_win ? 'predicted' : ''; // Blue if predicted to win
    }
  
    // Determine classes for team 2 (away team)
    let team2Class = '';
    if (isFinished) {
      if (match.Away_win) {
        // Predicted away team to win
        if (match.Match) {
          // Prediction was correct, away team actually won
          team2Class = 'winner'; // Green for correct prediction
        } else {
          // Prediction was incorrect, away team lost
          team2Class = 'wrong'; // Yellow for incorrect prediction
        }
      } else {
        team2Class = ''; // Default styling if prediction was for home to win
      }
    } else {
      // Match not finished yet
      team2Class = match.Away_win ? 'predicted' : ''; // Blue if predicted to win
    }

  // Icons for prediction correctness
  const correctIcon = isFinished && match.Match 
    ? <span className="correct-icon">✓</span>
    : <span className="wrong-icon">❌</span>;
 
    
  
  return (
    <div className="match">
      {/* Team 1 */}
      <div className="team-container">
        <div className="team-icon">
          <img src={match.team1_logo} alt={match.team1} />
        </div>
        <div className={`team ${team1Class} flex-start`}>
          <span>{match.team1}</span>
        </div>
      </div>

      {/* Score or VS */}
      {isFinished ? (
        <div className="score">
          {match.result_team1} - {match.result_team2}
          {correctIcon}
          <div className="date">{match.date}</div>
        </div>
      ) : isRunning ? (
        <div className="score">
          {match.result_team1} - {match.result_team2}
          <span style={{ color: 'red',  marginLeft: '8px' }}>●</span>
          <div className="date">{match.date}</div>
        </div>
      ) : (
        <div className="vs">
          vs
          <div className="date">{match.date}</div>
        </div>
      )}



      {/* Team 2 */}
      <div className="team-container">
        <div className={`team ${team2Class} flex-end`}>
          <span>{match.team2}</span>
        </div>
        <div className="team-icon">
          <img src={match.team2_logo} alt={match.team2} />
        </div>
      </div>
    </div>
  );
}










export default App;
